// libraries
import { Dispatch, SetStateAction } from 'react'
import _ from 'lodash'
import { Node, Edge, XYPosition } from 'reactflow'
import { ValidationErrors } from 'final-form'
import { UiSchema, ErrorTransformer } from '@rjsf/utils'
import { JSONSchema7 } from 'json-schema'

// utils
import { uuidv4 } from 'helpers/common'
import { sanitizeFormDataForObject } from 'components/common/JsonForm/GeoPointWidget'
import { getAllJSONFormProperties } from 'helpers/issue'

// constants
import {
  FORM_QUESTION_TYPES,
  FORM_BUILDER_WIDGET_SPECS_KEY_BY_WIDGET_TYPE,
  FORM_BUILDER_CANVAS_NODE_TYPES,
  FORM_BUILDER_CANVAS_EDGE_TYPES,
  JSON_DATA_TYPES,
  FORM_BUILDER_CANVAS_SYSTEM_TYPES,
  DEFAULT_FORM_WIDGETS,
  STRING_FORMATS,
  CUSTOM_WIDGETS_AND_FIELDS,
  FORM_VALIDATION_ERRORS_MAP,
  GEOPOINT_DEFINITION_REFERENCE,
  DATE_TIME_FIELD_TYPES_ARRAY,
  DATE_TIME_FIELD_TYPES,
} from 'constants/formBuilder'

import type {
  FormEnum,
  FormEnumNames,
  FormBuilderNode,
  FormSchemaFormValues,
  JSONFormSchema,
  JSONFormWidgetUiSchema,
  FormBuilderNodeData,
  JSONFormUiSchema,
  FormQuestionType,
  FormBuilderCanvasPayload,
  DateTimeFieldFormat,
  OneOfConditions,
} from 'types/formBuilder'
import type { Options, Payload, SpecParams } from 'types/common'
import pointSchema from 'constants/schema/point.json'

export const isJsonFormWidgetUiOptionSet =
  (option: string): ((uiSchema: JSONFormUiSchema) => boolean) =>
  (uiSchema: JSONFormUiSchema) =>
    !!_.get(uiSchema, ['ui:options', option])

export const isJsonFormWidgetHidden = isJsonFormWidgetUiOptionSet('hidden')
export const isJsonFormWidgetLocked = isJsonFormWidgetUiOptionSet('locked')
export const isJsonFormWidgetReadonly = isJsonFormWidgetUiOptionSet('readonly')
export const isJsonFormWidgetHideLabel =
  isJsonFormWidgetUiOptionSet('hideLabel')

export const isJsonFormWidgetRequired = (uischema: JSONFormSchema): boolean =>
  !!_.get(uischema, 'required')

export const isJsonFormWidgetInline = isJsonFormWidgetUiOptionSet('inline')

export const getNodeId = (): string => uuidv4()

export const isMultipleChoiceList = (
  schema: JSONFormSchema['properties']
): boolean => {
  const { items, type, uniqueItems } = schema
  return (!_.isEmpty(items) &&
    !_.isNil(items.enum) &&
    type === JSON_DATA_TYPES.array &&
    uniqueItems) as boolean
}

export const isImageFile = (
  schema?: JSONFormSchema,
  uiSchema?: JSONFormWidgetUiSchema
): boolean => {
  if (
    _.get(uiSchema, '[ui:options].mediaType') === 'image' ||
    _.get(uiSchema, '[ui:widget]') === CUSTOM_WIDGETS_AND_FIELDS.images
  ) {
    return true
  }
  if (schema?.items) {
    return (
      schema?.items.type === JSON_DATA_TYPES.string &&
      schema?.items.format === STRING_FORMATS['data-url']
    )
  }
  return false
}

export const isCheckboxList = (uiSchema?: JSONFormWidgetUiSchema): boolean => {
  if (_.get(uiSchema, 'ui:widget') === CUSTOM_WIDGETS_AND_FIELDS.checkboxes) {
    return true
  }

  return false
}

export const isRadioButton = (uiSchema?: JSONFormWidgetUiSchema): boolean =>
  _.get(uiSchema, 'ui:widget') === CUSTOM_WIDGETS_AND_FIELDS.radio

export const isGeojsonPoint = (
  schema?: JSONFormSchema,
  uiSchema?: JSONFormWidgetUiSchema
): boolean => {
  const hasRef = schema?.$ref === GEOPOINT_DEFINITION_REFERENCE
  const type = _.get(schema, 'properties.type.enum')
  const field = _.get(uiSchema, 'ui:field')

  return (
    hasRef ||
    (_.isEqual(type, ['Point']) && field === CUSTOM_WIDGETS_AND_FIELDS.geopoint)
  )
}

export const getFormBuilderWidgetType = ({
  schema,
  uischema,
}: {
  schema?: JSONFormSchema
  uischema?: JSONFormWidgetUiSchema
}): FormQuestionType | undefined => {
  const { enum: nodeEnum, items: nodeItems, type, format } = schema || {}

  // For array fields
  if (nodeEnum || nodeItems) {
    if (nodeItems) {
      if (uischema?.['ui:widget'] === CUSTOM_WIDGETS_AND_FIELDS.videos) {
        return FORM_QUESTION_TYPES.VIDEO_UPLOADER
      }
      if (uischema?.['ui:widget'] === CUSTOM_WIDGETS_AND_FIELDS.pdfs) {
        return FORM_QUESTION_TYPES.PDF_UPLOADER
      }
      if (isImageFile(schema, uischema)) {
        return FORM_QUESTION_TYPES.IMAGE_UPLOADER
      }
      if (isCheckboxList(uischema)) {
        return FORM_QUESTION_TYPES.CHECKBOX_LIST
      }

      if (!nodeEnum && nodeItems?.type === JSON_DATA_TYPES.object) {
        return FORM_QUESTION_TYPES.LIST
      }
    }

    if (isRadioButton(uischema)) {
      return FORM_QUESTION_TYPES.RADIO
    }

    return FORM_QUESTION_TYPES.DROPDOWN
  }

  if (type === JSON_DATA_TYPES.string) {
    if (DATE_TIME_FIELD_TYPES_ARRAY.includes(format as DateTimeFieldFormat)) {
      return FORM_QUESTION_TYPES.DATETIME
    }

    switch (uischema?.['ui:widget']) {
      case CUSTOM_WIDGETS_AND_FIELDS.barcode:
        return FORM_QUESTION_TYPES.SCANNER
      case CUSTOM_WIDGETS_AND_FIELDS.textarea:
        return FORM_QUESTION_TYPES.TEXTAREA
      case CUSTOM_WIDGETS_AND_FIELDS.heading:
        return FORM_QUESTION_TYPES.HEADING
      case CUSTOM_WIDGETS_AND_FIELDS.radio:
        return FORM_QUESTION_TYPES.RADIO
      default:
        return FORM_QUESTION_TYPES.TEXT_INPUT
    }
  }

  if (type === JSON_DATA_TYPES.number) {
    return FORM_QUESTION_TYPES.NUMBER
  }

  if (type === JSON_DATA_TYPES.boolean) {
    return FORM_QUESTION_TYPES.TOGGLE
  }

  if (isGeojsonPoint(schema, uischema)) {
    return FORM_QUESTION_TYPES.GEO_POINT
  }

  if (type === JSON_DATA_TYPES.object) {
    if (uischema?.['ui:field'] === CUSTOM_WIDGETS_AND_FIELDS.signature) {
      return FORM_QUESTION_TYPES.SIGNATURE
    }
  }

  return undefined
}

export const getFormValuesFromNodes = (
  nodes: FormBuilderNode[]
): FormSchemaFormValues => {
  const omitNodes = _.reject(nodes, node =>
    _.includes(FORM_BUILDER_CANVAS_SYSTEM_TYPES, node.type)
  )

  return _.reduce(
    omitNodes,
    (acc, param, index) => {
      const { id, schema, uischema } = param.data
      return id ? { ...acc, [id]: { id, schema, uischema, order: index } } : acc
    },
    {} as FormSchemaFormValues
  )
}

export const getStartNode = (): Partial<FormBuilderNode> => {
  const startNodeId = getNodeId()

  return {
    id: startNodeId,
    data: { showActionMenu: false, id: startNodeId },
    height: 18,
    position: { x: 257, y: 120 },
    selected: false,
    type: FORM_BUILDER_CANVAS_NODE_TYPES.START,
    width: 35,
  }
}

export const getAddNewNode = (): Partial<FormBuilderNode> => {
  const id = getNodeId()

  return {
    id,
    type: FORM_BUILDER_CANVAS_NODE_TYPES.NEW,
    data: {
      id,
    },
    position: { x: 139, y: 100 },
  }
}

export const getLayoutNode = (): Partial<FormBuilderNode> => {
  const id = getNodeId()

  return {
    id,
    data: {
      id,
    },
    type: FORM_BUILDER_CANVAS_NODE_TYPES.CONDITION,
    position: { x: 220, y: 200 },
  }
}

export const getInputNode = ({
  schema,
  uischema,
  icon,
}: {
  schema: JSONFormSchema
  uischema: JSONFormWidgetUiSchema
  icon: string
}): Partial<FormBuilderNode> => {
  const newNodeId = getNodeId()

  return {
    id: newNodeId,
    type: FORM_BUILDER_CANVAS_NODE_TYPES.INPUT,
    selected: true,
    data: {
      id: newNodeId,
      schema,
      uischema,
      showActionMenu: true,
      icon,
    },
    style: { border: '1px solid #ddd' },
    position: { x: 220, y: 200 },
  }
}

export const getEndNode = (): Partial<FormBuilderNode> => {
  const endNodeId = getNodeId()

  return {
    id: endNodeId,
    data: {
      id: endNodeId,
    },
    position: { x: 261, y: 520 },
    type: FORM_BUILDER_CANVAS_NODE_TYPES.END,
  }
}

export const getNodesFromFormValues = ({
  formValues,
  error,
  selectedNodeId,
  isDisabled,
}: {
  formValues: FormSchemaFormValues
  error: ValidationErrors
  selectedNodeId?: string
  isDisabled: boolean
}): FormBuilderNode[] => {
  if (_.isEmpty(formValues)) return [getAddNewNode()]

  const nodesLength = _.keys(formValues).length

  const sortedNodes = _(formValues)
    .sortBy('order')
    .map((formValue, idx) => {
      const { id, schema, uischema } = formValue as FormBuilderNodeData

      const widgetType =
        getFormBuilderWidgetType({ schema, uischema }) ||
        FORM_QUESTION_TYPES.TEXT_INPUT

      const { icon } = FORM_BUILDER_WIDGET_SPECS_KEY_BY_WIDGET_TYPE[widgetType]
      const errors = _.get(error, [id, 'schema'])
      const isSelected = selectedNodeId === id
      const isLocked = isJsonFormWidgetLocked(uischema as JSONFormUiSchema)

      return {
        id,
        deletable: !isLocked,
        position: { x: 220, y: 320 },
        selected: isSelected,
        style: errors
          ? { boxShadow: '0 0 0 2.5px #e30000' }
          : { border: '1px solid #ddd' },
        type: FORM_BUILDER_CANVAS_NODE_TYPES.INPUT,
        width: 108,
        height: 53,
        data: {
          id,
          schema,
          uischema,
          icon,
          error: errors,
          errorMessage: _.values(errors),
          showActionMenu: isSelected,
          isLast: idx === nodesLength - 1,
        },
      }
    })
    .value()
  return [
    getStartNode(),
    ...sortedNodes,
    isDisabled ? getEndNode() : getAddNewNode(),
  ]
}

export const getEdgesFromNodes = (
  nodes: FormBuilderNode[],
  isStraight = false
): Edge[] => {
  if (nodes.length === 1) return []

  const edges = []
  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i + 1]) {
      edges.push({
        id: `e_${nodes[i].id}_${nodes[i + 1].id}`,
        source: nodes[i].id,
        target: nodes[i + 1].id,
        type: isStraight
          ? FORM_BUILDER_CANVAS_EDGE_TYPES.STANDARD
          : FORM_BUILDER_CANVAS_EDGE_TYPES.PLACEHOLDER,
        hidden: false,
      })
    }
  }

  return edges
}

type SetEdgePositionsType = {
  nodes: FormBuilderNode[]
  setSelected?: boolean
  clonedNodeIndex?: number
  clientXY: XYPosition
}

export const setEdgePositions = ({
  nodes,
  setSelected,
  clonedNodeIndex,
  clientXY,
}: SetEdgePositionsType): FormBuilderNode[] => {
  const newNodes = nodes
  let newPosition = clientXY.y / 2

  for (let i = newNodes.length - 1; i >= 0; i--) {
    if (setSelected && i !== clonedNodeIndex) {
      newNodes[i].selected = false
      newNodes[i].data.showActionMenu = false
    }

    newNodes[i].position.x = clientXY.x - 54
    newNodes[i].position.y = newPosition
    newPosition -= 130
  }

  const firstNodeYPosition = newNodes[0].position.y
  newNodes[0].position.y = firstNodeYPosition + 60
  newNodes[0].position.x = clientXY.x - 16
  newNodes[newNodes.length - 1].position.x =
    newNodes[newNodes.length - 1].type === FORM_BUILDER_CANVAS_NODE_TYPES.END
      ? clientXY.x - 12
      : clientXY.x - 134

  return newNodes
}

export const isFormBuilderCanvasLayoutNode = (nodeType?: string): boolean =>
  _.includes(FORM_BUILDER_CANVAS_SYSTEM_TYPES, nodeType)

export const setEdgeStyles = (nodes: FormBuilderNode[]): FormBuilderNode[] => {
  return nodes.map(obj => {
    if (!isFormBuilderCanvasLayoutNode(obj.type)) {
      obj.style = obj.data.error
        ? { boxShadow: '0 0 0 2.5px #e30000' }
        : { border: '1px solid #ddd' }
    }

    return obj
  })
}

export const getEnumOptions = (props: {
  enum: FormEnum
  enumNames?: FormEnumNames
  default?: string
}): Options<string> => {
  const {
    enum: inputEnum = [],
    enumNames = [],
    default: defaultValue,
  } = props || {}
  return _.map(inputEnum, (value, idx) => {
    return {
      value,
      label: _.isEmpty(enumNames) ? value : enumNames[idx] || value,
      ...(value === defaultValue && { default: value === defaultValue }),
    }
  })
}

export const getEnumItems = (
  data: Options<string>
): {
  enum: FormEnum
  enumNames: FormEnumNames
} => {
  return {
    enum: _.map(data, 'value'),
    enumNames: _.map(data, 'label'),
  }
}

export const getDefaultJsonFormSchema = (
  jsonFormBody = {}
): {
  schema: JSONFormSchema
  uischema: JSONFormUiSchema
} => {
  const { schema, uischema } = jsonFormBody || {}
  const defaultKeys = _.map(DEFAULT_FORM_WIDGETS, 'key')

  if (!defaultKeys.every(key => _.has(schema?.properties, key))) {
    return {
      schema: {
        ...schema,
        required: _.compact([...defaultKeys, ...(schema?.required || [])]),
        properties: {
          ..._.reduce(
            DEFAULT_FORM_WIDGETS,
            (acc, cur) => {
              const { widgetType, title, key } = cur
              return {
                ...acc,
                [key]: {
                  ...FORM_BUILDER_WIDGET_SPECS_KEY_BY_WIDGET_TYPE[widgetType]
                    .schema,
                  title,
                },
              }
            },
            {}
          ),
          ...schema?.properties,
        },
      },
      uischema: {
        ...uischema,
        'ui:order': _.compact([
          ...defaultKeys,
          ...(_.get(uischema, 'ui:order') || []),
        ]),
        ..._.reduce(
          DEFAULT_FORM_WIDGETS,
          (acc, cur) => {
            const { widgetType, key } = cur
            const uiSchema =
              FORM_BUILDER_WIDGET_SPECS_KEY_BY_WIDGET_TYPE[widgetType].uischema
            const uiOptions = _.get(cur, 'ui:options')

            if (!uiSchema && !uiOptions) return acc

            return {
              ...acc,
              [key]: { ...uiSchema, 'ui:options': uiOptions },
            }
          },
          {}
        ),
      },
    }
  }

  return { schema, uischema }
}

type DefaultValue = string

export const getValueWhenSwitchingBetweenSingleSelectAndMultiSelect = (
  oldValue: DefaultValue | DefaultValue[] | undefined,
  isMulti: boolean
): DefaultValue | DefaultValue[] | undefined => {
  if (!oldValue) return undefined

  if (isMulti) {
    return _.isEmpty(oldValue)
      ? []
      : _.isArray(oldValue)
      ? oldValue
      : [oldValue]
  }
  return _.isArray(oldValue) ? '' : oldValue
}

/** Replace some error massages */
type FormBuilderError = ErrorTransformer & { message: string | undefined }

export const transformErrors = (
  errorsToTransform: ErrorTransformer[]
): FormBuilderError[] =>
  errorsToTransform.map(error => {
    const transformError = FORM_VALIDATION_ERRORS_MAP[error.name]
    return {
      ...error,
      message: transformError ? transformError(error) : error.message,
    }
  })

export const prepareForGeojsonPoint = (
  schema: JSONFormSchema
): JSONFormSchema => {
  return {
    ...schema,
    properties: {
      ...schema.properties,
      bbox: { ...schema.properties.bbox, default: null },
    },
  }
}

/** Transform the value to a format which is acceptable by JsonSchema (with seconds, 20:20:39) */
export const transformTimeValue = (timeValue?: string): string | undefined =>
  _.isString(timeValue) && timeValue.length === 5
    ? `${timeValue}:00`
    : timeValue

const isGeolocationSchema = (schema: JSONFormSchema) =>
  _.get(schema, '$id') === _.get(pointSchema, '$id')

const isGeolocationWidget = (fieldUISchema: UiSchema) =>
  _.get(fieldUISchema, '[ui:field]') === CUSTOM_WIDGETS_AND_FIELDS.geopoint

const tweakDependencies = (
  dependencies: JSONFormSchema['dependencies'],
  allTweakedProperties: Record<string, unknown>
) =>
  // Iterating through [['fieldKey', { oneOf: [...] }], ...]
  Object.entries(dependencies).reduce(
    (tweakedDependencies, [conditionParentKey, conditionSchema]) => {
      const modifiedSchema = {
        ...(conditionSchema as {
          oneOf: OneOfConditions
        }),
      }

      // Iterating through condition branches
      const modifiedProps = conditionSchema.oneOf.map(conditionBranch => {
        const modifiedProperties = { ...conditionBranch.properties }

        // Iterating though 'properties' inside of condition branches
        Object.keys(conditionBranch.properties).forEach(propertyKey => {
          // The 'properties' object always has 1 property
          // with a key corresponding to a dependency parent,
          // describing the condition that should be met for that branch to be displayed.
          // It's a metadata so we don't want to mix it with the property schema.
          if (conditionParentKey !== propertyKey) {
            modifiedProperties[propertyKey] = {
              ...conditionBranch.properties[propertyKey],
              ...(allTweakedProperties[propertyKey] || {}),
            }
          }
        })

        return { ...conditionBranch, properties: modifiedProperties }
      })

      modifiedSchema.oneOf = modifiedProps
      tweakedDependencies[conditionParentKey] = modifiedSchema

      return tweakedDependencies
    },
    {} as Record<
      string,
      {
        oneOf: OneOfConditions
      }
    >
  )

/** Tweak 'formData' and 'jsonSchema' to properly validate a form */
export const prepareForValidation = (
  formData: Payload,
  schema?: JSONFormSchema,
  uischema?: UiSchema
): { formData: Payload; schema?: JSONFormSchema } => {
  if (!schema || !uischema) return { formData, schema }

  const hiddenFields = _.reduce(
    uischema,
    (acc, fieldUISchema, key) => {
      // Do not validate hidden fields
      const isHidden = isJsonFormWidgetHidden(fieldUISchema)
      // Skip validation for image, video and pdf widgets since the schema that we need to use
      // doesn't really correspond to the real data shape that we're storing.
      // For example, we need to use {format: 'data-url'} to make sure that those widgets are
      // recognizable by the backend, but the data format isn't really a URL.
      const isMediaWidget = [
        CUSTOM_WIDGETS_AND_FIELDS.images,
        CUSTOM_WIDGETS_AND_FIELDS.videos,
        CUSTOM_WIDGETS_AND_FIELDS.pdfs,
      ].includes(_.get(fieldUISchema, '[ui:widget]'))

      // Skip the validation of geo widget because of the known issue related to $defs usage
      // https://github.com/ajv-validator/ajv/issues/1707
      // The issue was marked as closed but it's not resolved
      const isGeoWidget = isGeolocationWidget(fieldUISchema)

      return isHidden || isMediaWidget || isGeoWidget ? [...acc, key] : acc
    },
    [] as string[]
  )

  // New list of required fields without hidden fields
  const required = _.difference(schema.required, hiddenFields)
  const allProperties = getAllJSONFormProperties({ schema, uischema })

  // Filter hidden fields from the schema so we will not validate them
  const propertiesWithoutHidden = _.omit(allProperties, hiddenFields)
  const allTweakedProperties = _.mapValues(
    propertiesWithoutHidden,
    (fieldSchema, key) => {
      const isRequired = required.includes(key)

      const isArray = fieldSchema.type === 'array'

      // If an array field is required, set 'minItems: 1' by default
      // because we expect to have at least 1 value inside
      if (isRequired && isArray && !fieldSchema.minItems) {
        return { ...fieldSchema, minItems: 1 }
      }

      // if the schema is for Geo location point, add default: null to the bbox so that bbox could be optional
      if (isGeolocationSchema(fieldSchema)) {
        return prepareForGeojsonPoint(fieldSchema)
      }

      return fieldSchema
    }
  )

  const rootPropertyKeys = Object.keys(schema.properties)
  const rootTweakedProperties = _.pick(allTweakedProperties, rootPropertyKeys)

  const newSchema = {
    ...schema,
    required,
    properties: rootTweakedProperties,
    ...(schema.dependencies
      ? // Also tweak dependencies so they will be properly validated
        tweakDependencies(schema.dependencies, allTweakedProperties)
      : {}),
  }

  // Filter hidden fields from the form data
  const formDataWithoutHidden: SpecParams = _.omit(formData, hiddenFields)
  const newFormData = _.mapValues(formDataWithoutHidden, (fieldValue, key) => {
    const fieldSchema: JSONSchema7 | undefined = allTweakedProperties[key]

    // JsonSchema doesn't like empty strings when it's a date/time format
    if (
      DATE_TIME_FIELD_TYPES_ARRAY.includes(
        fieldSchema?.format as DateTimeFieldFormat
      ) &&
      fieldValue === ''
    ) {
      return undefined
    }

    if (fieldSchema?.format === DATE_TIME_FIELD_TYPES.time) {
      return transformTimeValue(fieldValue as string)
    }

    const isBoolean = fieldSchema?.type === JSON_DATA_TYPES.boolean
    // If there is no value set in the boolean field, set 'false' by default
    if (isBoolean && !_.isBoolean(fieldValue)) {
      return false
    }

    // If it's a number widget, convert string to a number
    if (fieldSchema?.type === JSON_DATA_TYPES.number) {
      return _.isString(fieldValue) ? _.toNumber(fieldValue) : fieldValue
    }

    if (_.isObject(fieldValue) && !_.isArray(fieldValue)) {
      return sanitizeFormDataForObject(fieldValue)
    }

    return fieldValue
  })

  return {
    formData: newFormData,
    schema: newSchema,
  }
}

export const getFieldName = (nodeId: string, name: string): string =>
  `${nodeId}.schema.${name}`

export const deleteNode = ({
  id,
  nodes,
  clientXY,
  handleSetEdgePositions,
  handleGetEdgesFromNodes,
  handleSetNodes,
  handleSetEdges,
  handleFormDesignerOnChange,
}: {
  id: string
  nodes: FormBuilderNode[]
  clientXY: XYPosition
  handleSetEdgePositions: (x: SetEdgePositionsType) => FormBuilderNode[]
  handleGetEdgesFromNodes: (
    nds: FormBuilderNode[],
    isStraight?: boolean
  ) => Edge[]
  handleSetNodes: Dispatch<SetStateAction<Node<FormBuilderNodeData>[]>>
  handleSetEdges: Dispatch<SetStateAction<Edge[]>>
  handleFormDesignerOnChange: (formValues: FormBuilderCanvasPayload) => void
}): void => {
  const allNodes = nodes
  const node = _.find(nodes, { id })
  const deletable = _.get(node, 'deletable')
  if (!node || !deletable) return

  const nodesWithoutCurrentOne = _.reject(allNodes, { id })
  const newNodes = handleSetEdgePositions({
    nodes: nodesWithoutCurrentOne,
    clientXY,
  })
  const newEdges = handleGetEdgesFromNodes(newNodes)
  handleSetNodes(newNodes)
  handleSetEdges(newEdges)
  handleFormDesignerOnChange({
    form: newNodes,
    selectedNode: undefined,
  })
}

export const getWidgetInputDefaultClassNames = (isPreview?: boolean): string =>
  `form-control ${isPreview ? 'isLargeWidget' : 'isRegularWidget'}`
