import { useMemo } from 'react'
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
  TypePolicy,
  FieldPolicy,
  FetchPolicy,
  FieldFunctionOptions,
} from '@apollo/client'
import { relayStylePagination, StoreObject } from '@apollo/client/utilities'
import { errorLink } from 'apollo/links'

import { useConfigStateValue } from 'contexts'
import { isDevEnvironment } from 'helpers/utils'
import { APOLLO_FETCH_POLICY } from 'apollo/constants'

/**
 * A custom merge functions for non-normalized nested fields in cache
 * https://www.apollographql.com/docs/react/caching/cache-field-behavior#merging-non-normalized-objects
 */
const mergeObjectsFunction = <T extends StoreObject>(
  existing: T,
  incoming: T,
  { mergeObjects }: { mergeObjects: FieldFunctionOptions<T>['mergeObjects'] }
): T => {
  return mergeObjects(existing, incoming)
}

const getByIdFieldPolicy = (): { byId: FieldPolicy } => ({
  byId: {
    merge: mergeObjectsFunction,
  },
})

/**
 * ! Add a type here to fix this Apollo error:
 * "Cache data may be lost when replacing the {typeName} field of a Query object."
 */
const TYPES_TO_FIX_BY_ID_QUERY_CACHING = ['Issues', 'EmissionObservations']

const isDevEnv = isDevEnvironment()

const useInitApolloClient = ({
  clientName,
  clientVersion,
}: {
  clientName: string
  clientVersion: string
}) => {
  const { graphqlApi } = useConfigStateValue() || {}

  return useMemo(() => {
    const link = ApolloLink.from([
      errorLink,
      new HttpLink({
        uri: isDevEnv ? 'http://localhost:3000/api/graphql' : graphqlApi,
      }),
    ])

    return new ApolloClient({
      name: clientName,
      version: clientVersion,
      defaultOptions: {
        watchQuery: {
          // Supported fetch policies
          // https://www.apollographql.com/docs/react/data/queries#supported-fetch-policies
          fetchPolicy: APOLLO_FETCH_POLICY.CACHE_FIRST,
          // Saves both data and errors in the Apollo Cache, allowing UI to
          // handle them
          // `none` is the default policy to match how Apollo Client 1.0 worked.
          // Any GraphQL Errors are treated the same as network errors and any
          // data is ignored from the response.
          // can still override this global setting for individual operations by
          // specifying the errorPolicy in the options for that specific query
          // or mutation
          // https://www.apollographql.com/docs/react/v2/data/error-handling#error-policies
          errorPolicy: 'all',
        },
        query: {
          fetchPolicy: APOLLO_FETCH_POLICY.CACHE_FIRST as FetchPolicy,
          errorPolicy: 'all',
        },
        mutate: {
          errorPolicy: 'all',
        },
      },
      cache: new InMemoryCache({
        typePolicies: {
          ...TYPES_TO_FIX_BY_ID_QUERY_CACHING.reduce<
            Record<string, TypePolicy>
          >(
            (acc, typeName) => ({
              ...acc,
              [typeName]: {
                keyFields: [],
                fields: getByIdFieldPolicy(),
              },
            }),
            {}
          ),
          // This is the type of a query domain. For example, if we're working with the query 'emissionEvents.inbox',
          // that means that 'emissionEvents' is a domain. You can easily find its type in Apollo Studio.
          EmissionEvents: {
            fields: {
              // Basically the name of the query
              all: relayStylePagination(),
            },
          },
          ReconciliationReports: {
            merge: mergeObjectsFunction,
            fields: {
              // The keyArgs(['year', 'filter', 'unit']) specifies the key
              // arguments that should be used to uniquely identify different
              // sets of paginated results.
              // https://www.apollographql.com/docs/react/pagination/key-args
              assetBasedByYear: relayStylePagination([
                'year',
                'assetReference',
                'filter',
              ]),
              assetBasedByMonth: relayStylePagination([
                'month',
                'assetReference',
                'filter',
              ]),
              sourceCategoryBasedByAssetMonth: relayStylePagination([
                'month',
                'assetReference',
              ]),
            },
          },
          Assets: {
            keyFields: [],
            fields: {
              all: relayStylePagination(['filter', ['modifiedAfter']]),
              ...getByIdFieldPolicy(),
            },
          },
        },
      }),
      link,
      connectToDevTools: isDevEnv,
    })
  }, [clientName, clientVersion, graphqlApi])
}

export default useInitApolloClient
