import { AjvError } from '@rjsf/core'
import keymirror from 'keymirror'
import _ from 'lodash'

import pointSchema from './schema/point.json'

export const FORM_BUILDER_WIDGET_CATEGORIES = keymirror({
  SYSTEM_ELEMENT: null,
  INPUT_ELEMENT: null,
  LAYOUT_ELEMENT: null,
})

export const FORM_LAYOUT_TYPES = keymirror({
  conditions: null,
})

export const FORM_QUESTION_TYPES = keymirror({
  TEXT_INPUT: null,
  TEXTAREA: null,
  NUMBER: null,
  DROPDOWN: null,
  CHECKBOX_LIST: null,
  TOGGLE: null,
  SCANNER: null,
  IMAGE_UPLOADER: null,
  VIDEO_UPLOADER: null,
  PDF_UPLOADER: null,
  GEO_POINT: null,
  DATETIME: null,
  HEADING: null,
  SIGNATURE: null,
  RADIO: null,
  END: null,
  LIST: null,
})

export const FORM_BUILDER_WIDGET_LAYOUT_TYPES = keymirror({
  DIVIDER: null,
})

export const FORM_BUILDER_CANVAS_NODE_TYPES = keymirror({
  START: null,
  END: null,
  NEW: null,
  PLACEHOLDER: null,
  INPUT: null,
  CONDITION: null,
})

export const FORM_BUILDER_CANVAS_SYSTEM_TYPES = [
  FORM_BUILDER_CANVAS_NODE_TYPES.START,
  FORM_BUILDER_CANVAS_NODE_TYPES.END,
  FORM_BUILDER_CANVAS_NODE_TYPES.NEW,
  FORM_BUILDER_CANVAS_NODE_TYPES.PLACEHOLDER,
]

export const FORM_BUILDER_CANVAS_EDGE_TYPES = keymirror({
  PLACEHOLDER: null,
  STANDARD: null,
})

export const JSON_DATA_TYPES = keymirror({
  string: null,
  number: null,
  integer: null,
  boolean: null,
  array: null,
  object: null,
  null: null,
})

export const STRING_FORMATS = keymirror({
  'date-time': null,
  'data-url': null,
})

export const CUSTOM_WIDGETS_AND_FIELDS = keymirror({
  checkboxes: null,
  images: null,
  videos: null,
  geopoint: null,
  textarea: null,
  barcode: null,
  heading: null,
  signature: null,
  radio: null,
  pdfs: null,
})

export const FORM_BUILDER_MEDIA_CONFIGS = {
  max: 10,
  default: 5,
  min: 1,
  step: 1,
}

export const NUMBER_FIELD_TYPES = keymirror({
  input: null,
  stepper: null,
  slider: null,
})

export const DATE_TIME_FIELD_TYPES = keymirror({
  'date-time': null,
  date: null,
  time: null,
})

export const DATE_TIME_FIELD_TYPES_ARRAY = Object.values(DATE_TIME_FIELD_TYPES)

export const HEADING_SIZES = keymirror({
  large: null,
  medium: null,
  small: null,
})

export const FIELD_ADDON_POSITIONS = keymirror({
  left: null,
  right: null,
})

export const GEOPOINT_DEFINITION_KEY = 'geopoint'
export const GEOPOINT_DEFINITION_REFERENCE = `#/$defs/${GEOPOINT_DEFINITION_KEY}`

export const GEOPOINT_SCHEMA = pointSchema

export const DEFAULT_SCHEMA_DEFINITIONS = {
  [GEOPOINT_DEFINITION_KEY]: GEOPOINT_SCHEMA,
}

export const FORM_BUILDER_WIDGET_SPECS = [
  // {
  //   label: 'Conditions',
  //   widgetType: FORM_LAYOUT_TYPES.conditions,
  //   icon: 'ConditionsIcon',
  //   category: FORM_BUILDER_WIDGET_CATEGORIES.LAYOUT_ELEMENT,
  // },
  {
    label: 'Text input',
    widgetType: FORM_QUESTION_TYPES.TEXT_INPUT,
    icon: 'TextInputIcon',
    category: FORM_BUILDER_WIDGET_CATEGORIES.INPUT_ELEMENT,
    schema: { type: JSON_DATA_TYPES.string },
  },
  {
    label: 'Textarea',
    widgetType: FORM_QUESTION_TYPES.TEXTAREA,
    icon: 'TextareaIcon',
    category: FORM_BUILDER_WIDGET_CATEGORIES.INPUT_ELEMENT,
    schema: { type: JSON_DATA_TYPES.string },
    uischema: { 'ui:widget': CUSTOM_WIDGETS_AND_FIELDS.textarea },
  },
  {
    label: 'Dropdown',
    widgetType: FORM_QUESTION_TYPES.DROPDOWN,
    category: FORM_BUILDER_WIDGET_CATEGORIES.INPUT_ELEMENT,
    icon: 'DropdownWidgetIcon',
    schema: { type: JSON_DATA_TYPES.string, enum: [] },
  },
  {
    label: 'Number',
    widgetType: FORM_QUESTION_TYPES.NUMBER,
    icon: 'NumberWidgetIcon',
    category: FORM_BUILDER_WIDGET_CATEGORIES.INPUT_ELEMENT,
    schema: { type: JSON_DATA_TYPES.number },
  },
  {
    label: 'Toggle (Yes/No)',
    widgetType: FORM_QUESTION_TYPES.TOGGLE,
    category: FORM_BUILDER_WIDGET_CATEGORIES.INPUT_ELEMENT,
    icon: 'ToggleWidgetIcon',
    schema: {
      type: JSON_DATA_TYPES.boolean,
    },
  },
  {
    label: 'Checklist',
    widgetType: FORM_QUESTION_TYPES.CHECKBOX_LIST,
    category: FORM_BUILDER_WIDGET_CATEGORIES.INPUT_ELEMENT,
    icon: 'MdOutlineCheckBox',
    schema: {
      type: JSON_DATA_TYPES.array,
      items: {
        type: JSON_DATA_TYPES.string,
        enum: [],
      },
      uniqueItems: true,
    },
    uischema: { 'ui:widget': CUSTOM_WIDGETS_AND_FIELDS.checkboxes },
  },
  {
    label: 'Radio',
    widgetType: FORM_QUESTION_TYPES.RADIO,
    category: FORM_BUILDER_WIDGET_CATEGORIES.INPUT_ELEMENT,
    icon: 'RadioWidgetIcon',
    schema: {
      type: JSON_DATA_TYPES.array,
      items: {
        type: JSON_DATA_TYPES.string,
        enum: [],
      },
      uniqueItems: true,
    },
    uischema: { 'ui:widget': CUSTOM_WIDGETS_AND_FIELDS.radio },
  },
  {
    label: 'Scanner',
    widgetType: FORM_QUESTION_TYPES.SCANNER,
    icon: 'ScannerWidgetIcon',
    category: FORM_BUILDER_WIDGET_CATEGORIES.INPUT_ELEMENT,
    schema: { type: JSON_DATA_TYPES.string },
    uischema: { 'ui:widget': CUSTOM_WIDGETS_AND_FIELDS.barcode },
  },
  {
    label: 'Photo',
    widgetType: FORM_QUESTION_TYPES.IMAGE_UPLOADER,
    category: FORM_BUILDER_WIDGET_CATEGORIES.INPUT_ELEMENT,
    icon: 'PhotoWidgetIcon',
    schema: {
      type: JSON_DATA_TYPES.array,
      maxItems: FORM_BUILDER_MEDIA_CONFIGS.default,
      items: {
        type: JSON_DATA_TYPES.object,
        properties: {
          mediaKey: {
            type: JSON_DATA_TYPES.string,
            format: STRING_FORMATS['data-url'],
          },
          description: {
            type: JSON_DATA_TYPES.string,
          },
        },
      },
    },
    uischema: { 'ui:widget': CUSTOM_WIDGETS_AND_FIELDS.images },
  },
  {
    label: 'Video',
    widgetType: FORM_QUESTION_TYPES.VIDEO_UPLOADER,
    category: FORM_BUILDER_WIDGET_CATEGORIES.INPUT_ELEMENT,
    icon: 'BsCameraVideo',
    schema: {
      type: JSON_DATA_TYPES.array,
      maxItems: FORM_BUILDER_MEDIA_CONFIGS.default,
      items: {
        type: JSON_DATA_TYPES.object,
        properties: {
          mediaKey: {
            type: JSON_DATA_TYPES.string,
            format: STRING_FORMATS['data-url'],
          },
          description: {
            type: JSON_DATA_TYPES.string,
          },
        },
      },
    },
    uischema: { 'ui:widget': CUSTOM_WIDGETS_AND_FIELDS.videos },
  },
  {
    label: 'Upload',
    widgetType: FORM_QUESTION_TYPES.PDF_UPLOADER,
    category: FORM_BUILDER_WIDGET_CATEGORIES.INPUT_ELEMENT,
    icon: 'UploadIcon',
    schema: {
      type: JSON_DATA_TYPES.array,
      maxItems: FORM_BUILDER_MEDIA_CONFIGS.default,
      items: {
        type: JSON_DATA_TYPES.object,
        properties: {
          mediaKey: {
            type: JSON_DATA_TYPES.string,
            format: STRING_FORMATS['data-url'],
          },
          description: {
            type: JSON_DATA_TYPES.string,
          },
        },
      },
    },
    uischema: { 'ui:widget': CUSTOM_WIDGETS_AND_FIELDS.pdfs },
  },
  {
    label: 'Geolocation',
    widgetType: FORM_QUESTION_TYPES.GEO_POINT,
    category: FORM_BUILDER_WIDGET_CATEGORIES.INPUT_ELEMENT,
    icon: 'GeoWidgetIcon',
    schema: GEOPOINT_SCHEMA,
    uischema: {
      'ui:field': CUSTOM_WIDGETS_AND_FIELDS.geopoint,
    },
  },
  {
    label: 'Date and time',
    widgetType: FORM_QUESTION_TYPES.DATETIME,
    category: FORM_BUILDER_WIDGET_CATEGORIES.INPUT_ELEMENT,
    icon: 'DatePickerIcon',
    schema: {
      type: JSON_DATA_TYPES.string,
      format: DATE_TIME_FIELD_TYPES['date-time'],
    },
  },
  {
    label: 'Signature',
    widgetType: FORM_QUESTION_TYPES.SIGNATURE,
    category: FORM_BUILDER_WIDGET_CATEGORIES.INPUT_ELEMENT,
    icon: 'SignatureWidgetIcon',
    schema: {
      type: JSON_DATA_TYPES.object,
      properties: {
        mediaKey: { type: JSON_DATA_TYPES.string, format: 'data-url' },
        timestamp: {
          type: JSON_DATA_TYPES.string,
          format: STRING_FORMATS['date-time'],
        },
      },
    },
    uischema: { 'ui:field': CUSTOM_WIDGETS_AND_FIELDS.signature },
  },
  {
    label: 'Heading',
    widgetType: FORM_QUESTION_TYPES.HEADING,
    icon: 'HeadingWidgetIcon',
    category: FORM_BUILDER_WIDGET_CATEGORIES.INPUT_ELEMENT,
    schema: { type: JSON_DATA_TYPES.string },
    uischema: { 'ui:widget': CUSTOM_WIDGETS_AND_FIELDS.heading },
  },
  {
    label: 'List',
    widgetType: FORM_QUESTION_TYPES.LIST,
    icon: 'ListIcon',
    category: FORM_BUILDER_WIDGET_CATEGORIES.INPUT_ELEMENT,
    schema: { type: JSON_DATA_TYPES.array },
  },
]

export const DEFAULT_FORM_WIDGETS = _.map(
  [
    {
      widgetType: FORM_QUESTION_TYPES.GEO_POINT,
      title: 'Geolocation',
      key: 'currentLocation',
    },
    {
      widgetType: FORM_QUESTION_TYPES.DATETIME,
      title: 'Date and time',
      key: 'currentDatetime',
    },
  ],
  widget => ({
    ...widget,
    'ui:options': {
      hidden: true,
      locked: true,
    },
  })
)

export const FORM_BUILDER_WIDGET_SPECS_KEY_BY_WIDGET_TYPE = _.keyBy(
  FORM_BUILDER_WIDGET_SPECS,
  'widgetType'
)

export const FORM_BUILDER_PROPERTY_IDENTIFIER = 'id'

export const FORM_BUILDER_PROPERTY_NAME = 'key'

export const FORM_BUILDER_WIDGET_DROPDOWN_NAME = 'select'

export const FORM_BUILDER_WIDGET_PROPERTY_KEYS = keymirror({
  hideLabel: null,
  isHidden: null,
  readonly: null,
  smartScan: null,
  addonLabel: null,
  fieldType: null,
  format: null,
  limits: null,
  inline: null,
  size: null,
  required: null,
  tags: null,
})

export const FORM_VALIDATION_ERRORS_MAP: Record<
  string,
  (errorData?: AjvError) => string
> = {
  required: () => 'This field is required',
  minItems: () => 'This field is required',
  minimum: ({ params: { limit } }) =>
    `Should be greater than or equal to ${limit}`,
  maximum: ({ params: { limit } }) =>
    `Should be less than or equal to ${limit}`,
  minLength: ({ params: { limit } }) =>
    `The input must be greater than ${limit} characters`,
  maxLength: ({ params: { limit } }) =>
    `The input must be less than ${limit} characters`,
  laterDate: ({ params: { isTime } }) =>
    `Pick a later ${isTime ? 'time' : 'date'}`,
  timeBefore: ({ params: { endTime } }) => `Pick a time before ${endTime}`,
  timeAfter: ({ params: { startTime } }) => `Pick a time after ${startTime}`,
  timeBetween: ({ params: { startTime, endTime } }) =>
    `Pick between ${startTime} and ${endTime}`,
}

export const ADDON_LABEL_DEFAULT_VALUES = {
  visible: false,
  position: FIELD_ADDON_POSITIONS.right,
  label: '',
}

export const CALGARY_GEOGRAPHIC_COORDINATES = [-114.08529, 51.05011]
export const FORM_BUILDER_GEOLOCATION_WIDGET_DEFAULT_PIN = {
  coordinates: CALGARY_GEOGRAPHIC_COORDINATES,
  type: 'Point',
}

export const FORM_WIDGETS_DEFAULT_LABEL = 'Untitled label'

export const FORM_HEADING_WIDGET_DEFAULT_LABEL = 'Description goes here'

export const FIELD_PREFIX = 'root_'

export const jsonFormIdPrefix = 'jsonForm'

export const KNOWN_FIELD_TAGS = keymirror({ attribution_hint: null })
