import { ComponentProps } from 'react'
import { components as ReactSelectComponents } from 'react-select'

import { MdOutlineArrowDropDown } from 'components/icons'

const DropdownIndicator = (
  props: ComponentProps<typeof ReactSelectComponents.DropdownIndicator>
) => {
  return (
    <ReactSelectComponents.DropdownIndicator {...props}>
      <MdOutlineArrowDropDown size={24} />
    </ReactSelectComponents.DropdownIndicator>
  )
}

export default DropdownIndicator
