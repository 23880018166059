// libraries
import { useMemo } from 'react'

// constants
import { APP_URLS } from 'app/MissionControlMethaneSolution/constants/common'

// utils
import { getRouteUrlWithValues } from 'helpers/utils'
import { useCopyLinkButton } from 'hooks'

export const getDetectionLink = ({
  id,
  fullUrl = true,
  searchParams = '',
}: {
  id?: string
  fullUrl?: boolean
  searchParams?: string
}) =>
  id
    ? `${getRouteUrlWithValues(
        [
          ...(fullUrl ? [window.location.origin, process.env.PUBLIC_URL] : []),
          APP_URLS.EMISSION_OBSERVATIONS,
        ].join(''),
        { id }
      )}${searchParams ? `/?${searchParams}` : ''}`
    : ''

const useDetectionLink = (detectionId?: string) => {
  const detectionLink = useMemo(
    () => getDetectionLink({ id: detectionId }),
    [detectionId]
  )

  const detectionCopyLinkButton = useCopyLinkButton({ link: detectionLink })

  return { detectionCopyLinkButton, detectionLink }
}

export default useDetectionLink
