import { ReactElement } from 'react'
import styled from '@emotion/styled'
import _ from 'lodash'

// utils
import { getFileNameAndExt, getReadableBytes } from 'helpers/utils'
import { useStaticResourceStateValue } from 'contexts'

// components
import { IconButton, Tooltip } from 'components/common'

// constants
import { FILE_UPLOAD_STATUS } from 'constants/fileUpload'
import { TOOLTIP_PLACEMENT } from 'constants/settings'

import scss from './index.module.scss'
import FileUploadingStatusIcon from '../FileUploadingStatusIcon'

const StyledUploadedFilePreview = styled.div<{
  hasError?: boolean
  isDeleted?: boolean
}>`
  padding: 8px;
  background: #fafafa81;
  border: 1px solid
    ${({ hasError, theme }) => (hasError ? theme.danger : '#eaeaea')};
  opacity: ${({ isDeleted }) => (isDeleted ? 0.5 : 1)};
  border-radius: 5px;
`

const FileItem = ({
  className = '',
  name,
  path,
  size,
  downloadUrl,
  uploadingStatus,
  hasError,
  isDeleted,
  disabled,
  onDelete,
}: {
  className?: string
  name: string
  path?: string
  size?: number
  downloadUrl?: string
  uploadingStatus?: keyof typeof FILE_UPLOAD_STATUS
  hasError?: boolean
  isDeleted?: boolean
  disabled?: boolean
  onDelete?: (name: string) => void
}): ReactElement => {
  const [filename, ext] = getFileNameAndExt(name)
  const newName = `${_.truncate(filename, {
    length: 18,
    separator: ' ',
  })}.${ext}`

  const { Icons } = useStaticResourceStateValue()

  return (
    <StyledUploadedFilePreview
      className={`${scss.filePreview} d-flex justify-content-between align-items-start ${className}`}
      key={path || name}
      hasError={hasError}
      isDeleted={isDeleted}
    >
      <div className='d-flex'>
        <div style={{ marginTop: '5px' }}>
          <Icons.SpreadsheetIcon />
        </div>
        <div className={scss.filePreviewName}>
          <div className='defaultText'>{newName}</div>
          {_.isNumber(size) && <div>{getReadableBytes(size)}</div>}
        </div>
      </div>

      <div className='h-100 d-flex flex-column justify-content-between align-items-center'>
        <div className='d-flex flex-row align-items-center'>
          {downloadUrl && (
            <Tooltip
              placement={TOOLTIP_PLACEMENT.top}
              overlay={<span>Download</span>}
            >
              <IconButton
                icon='FaDownload'
                onClick={() => window.open(downloadUrl, '_blank', 'noreferrer')}
              />
            </Tooltip>
          )}

          {onDelete && (
            <Tooltip
              placement={TOOLTIP_PLACEMENT.top}
              overlay={<span>{isDeleted ? 'Restore' : 'Delete'}</span>}
            >
              <IconButton
                icon={isDeleted ? 'UndoIcon' : 'FiX'}
                disabled={disabled}
                onClick={() => {
                  onDelete?.(name)
                }}
              />
            </Tooltip>
          )}
        </div>

        {uploadingStatus && (
          <FileUploadingStatusIcon uploadingStatus={uploadingStatus} />
        )}
      </div>
    </StyledUploadedFilePreview>
  )
}

export default FileItem
