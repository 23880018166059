// libraries
import _ from 'lodash'
import { useContext, createContext, ReactElement, useCallback } from 'react'
import { useAsync } from 'react-use'

// constants
import { MAP_STYLE_TYPES, MAPBOX_DEFAULT_MAP_STYLES } from 'constants/map'
import { APPLICATION_NAME } from 'constants/common'

// utils
import { getConfig } from 'services/api/config'
import { getAuthConfig } from 'services/api/auth'
import { getVersions } from 'services/api/versions'
import RestApiService from 'services/api'
import GraphqlApiService from 'services/api/graphql'
import AuthService from 'services/authentication'
import Logging from 'services/logging'
import { checkLocalStorageEnabled, checkCookiesEnabled } from 'helpers/storage'
import { initSentry } from 'helpers/log'

// components
import { Loading, AppError } from 'components/common'

import type { AppConfig, Payload, Branding } from 'types/common'
import { initializeAnalytics } from 'helpers/firebase'

export const initialBrandingState = {
  baseMapStyle: MAP_STYLE_TYPES.dark,
  mapboxAccessToken:
    'pk.eyJ1Ijoic2Vuc29ydXAiLCJhIjoiY2ptdXpxYWMyMGNpbjNwbm9scWJhMGg0biJ9.zfGundgfsAh7M9DbdYXKfg',
  mapboxStyles: MAPBOX_DEFAULT_MAP_STYLES,
  backgroundImageUrl: '/assets/bg/Background.webp',
  logo: {
    fullLogo: {
      fullColourLogo: '/assets/logo/sensorup-logo-vertical.svg',
      fullDarkLogo: '/assets/logo/sensorup-logo-vertical.svg',
      fullWhiteLogo: '/assets/logo/sensorup-logo-vertical.svg',
    },
    logoIconOnly: {
      iconColourLogo: '/assets/logo/sensorup-logo-icon.svg',
      iconDarkLogo: '/assets/logo/sensorup-logo-icon-dark.svg',
      iconWhiteLogo: '/assets/logo/sensorup-logo-icon-light.svg',
    },
    navbarLogo: 'logoIconOnly.iconWhiteLogo',
    loginLogo: 'fullLogo.fullColourLogo',
  },
  colour: {
    dark: '#051b32',
    primary: '#1791EA',
    light: '#fafafa',
  },
  menus: {
    // make menu disappear or items be disabled if map not owned
    // configure disabled items with: branding.menu.disableInsteadOfHide
    disableInsteadOfHide: false,
  },
  title: 'SensorUp Mission Control',
  loginTitle: 'Control your silos',
  loginSubtitle: 'Connect your business',
  loginParagraph:
    'SensorUp Mission Control is a new way to analyze and visualize IoT & sensor observations in order to understand real-time systems of moving Things. Through streaming analytics we help customers better understand systems in motion.',
}

export const ConfigContext = createContext<AppConfig>({} as AppConfig)

const getFeatureFlags = (disableFeatures: Payload) =>
  _.reduce(
    disableFeatures,
    (result, value, key) => ({ ...result, [key]: !value }),
    {}
  )

const getBranding = (branding: Branding) => {
  const mapboxStyles = _.compact(
    _.unionBy(branding.mapboxStyles, initialBrandingState.mapboxStyles, 'value')
  )

  return Object.assign(
    _.defaultsDeep(
      // Omit the 'mapboxStyles' so the 'theme' property will not be weirdly predefined by lodash
      { ..._.omit(branding, ['mapboxStyles']) },
      initialBrandingState
    ),
    { mapboxStyles }
  )
}

export const checkAppServicesEnabled = (): boolean => {
  if (!checkCookiesEnabled()) return false
  if (!checkLocalStorageEnabled()) return false

  return true
}

export const ConfigProvider = ({
  children,
}: {
  children: ReactElement
}): ReactElement => {
  const initServices = useCallback(async (fetchedConfig: AppConfig) => {
    const {
      authentication,
      logging,
      api: { graphql_url: graphqlApi, ...restApi },
    } = fetchedConfig

    const basicApiConfigs = {
      ...restApi,
      baseUrl: graphqlApi,
    }

    GraphqlApiService.configure(basicApiConfigs)
    const releaseVersions = await getVersions()
    const { [APPLICATION_NAME]: suExplorerVersion, release: releaseVersion } =
      releaseVersions

    const clientName = APPLICATION_NAME
    const clientVersion = `${releaseVersion}/${suExplorerVersion}`

    const serviceConfigs = {
      ...basicApiConfigs,
      releaseVersion,
      suExplorerVersion,
      clientVersion,
      clientName,
    }

    Logging.configure(logging, releaseVersions)
    initSentry()

    GraphqlApiService.configure(serviceConfigs)
    RestApiService.configure(serviceConfigs)
    AuthService.configure(authentication)

    return { releaseVersions, graphqlApi, clientName, clientVersion }
  }, [])

  const { loading, error, value } = useAsync(async () => {
    if (!checkAppServicesEnabled()) {
      throw new Error(
        'Please enable cookies and local storage and reload the page.'
      )
    }

    const fetchedConfig = await getConfig()
    if (_.isEmpty(fetchedConfig)) {
      throw new Error('Missing App configs')
    }

    const { disableFeatures, branding, authentication, firebase } =
      fetchedConfig
    if (firebase) initializeAnalytics(firebase)

    const [{ graphqlApi, ...restServiceConfigs }, authConfig] =
      await Promise.all([initServices(fetchedConfig), getAuthConfig()])

    return {
      launchDarkly: {
        client_id: '655bdf52c3a8bb12577b6773',
      },
      ...fetchedConfig,
      ...authConfig,
      ...restServiceConfigs,
      authentication,
      graphqlApi,
      baseUrl: graphqlApi.replace('graphql', ''),
      branding: getBranding(branding),
      featureFlags: getFeatureFlags(disableFeatures),
    }
  }, [])

  return loading ? (
    <Loading />
  ) : error || !value ? (
    <AppError message={error?.message} />
  ) : (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  )
}

export const useConfigStateValue = (): AppConfig => useContext(ConfigContext)
