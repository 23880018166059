import _ from 'lodash'
import Moment from 'moment-timezone'
import { extendMoment } from 'moment-range'
import { toZonedTime, fromZonedTime } from 'date-fns-tz'
import * as chrono from 'chrono-node'

// constants
import {
  DATE_UNIT_TYPES,
  MOMENT_LOCALE_RELATIVE_TIME,
  INTERVAL_UNIT_OPTIONS,
  DATE_HOUR_MINUTE_FORMAT,
  DATE_HOUR_MINUTE_SECOND_TIMEZONE_FORMAT,
  DATE_HOUR_MINUTE_SECOND_FORMAT_STANDARD,
  TIME_ZONES_LIST,
  UTC,
  SECOND_IN_MS,
  YEAR_FORMAT,
  MONTH_NUMBER_FORMAT,
  DAY_NUMBER_FORMAT,
} from 'constants/datetime'

// utils
import { toLowerCase } from 'helpers/utils'

import type { Options } from 'types/common'
import type {
  Timezone,
  UtcTimeString,
  IntervalUnit,
  DateTimeRangeObj,
  UtcISOString,
  Datetime,
} from 'types/datetime'

const moment = extendMoment(Moment)

moment.updateLocale('en', {
  week: {
    dow: 1, // Monday is the first day of the week.
  },
})

export const isValidTimezone = (timezone?: Timezone): boolean => {
  return _.includes(TIME_ZONES_LIST, timezone)
}

export const getNowISOString = (): UtcISOString => moment().toISOString()

type GetSnappedDateMoment = {
  baseDatetime?: Datetime
  unit?: IntervalUnit
  timezone?: Timezone
  isStart?: boolean
}
export const getSnappedDateMoment = ({
  baseDatetime = '',
  unit = DATE_UNIT_TYPES.seconds,
  timezone,
  isStart = true,
}: GetSnappedDateMoment): Moment.Moment => {
  const snapFn = isStart ? 'startOf' : 'endOf'
  const dt = baseDatetime || undefined
  return timezone && isValidTimezone(timezone)
    ? moment(dt).tz(timezone)[snapFn](unit)
    : moment(dt).utc()[snapFn](unit)
}

export const getSnappedDatetimeStr = (
  props: GetSnappedDateMoment
): UtcTimeString => {
  return getSnappedDateMoment(props).toISOString()
}

export const isValidDatetimeUnit = (unit: IntervalUnit): boolean =>
  !!DATE_UNIT_TYPES[toLowerCase(unit)]

export const isBeforeDay = (a?: string, b?: string): boolean => {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false

  const aYear = a.year()
  const aMonth = a.month()

  const bYear = b.year()
  const bMonth = b.month()

  const isSameYear = aYear === bYear
  const isSameMonth = aMonth === bMonth

  if (isSameYear && isSameMonth) return a.date() < b.date()
  if (isSameYear) return aMonth < bMonth
  return aYear < bYear
}

export const isInclusivelyAfterDay = (a?: Datetime, b?: Datetime): boolean => {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false
  return !isBeforeDay(a, b)
}

type GetNewDateTime = Partial<{
  interval?: number
  intervalUnit?: IntervalUnit
  baseDatetime?: Datetime
  isFullDate?: boolean
  timezone?: Timezone
  isStart?: boolean
  snappedUnit?: IntervalUnit
}>

export const getNewDateTime =
  (isAdded = true) =>
  ({
    baseDatetime,
    interval = 1,
    intervalUnit = DATE_UNIT_TYPES.days,
    isFullDate = false,
    timezone,
    isStart,
    snappedUnit,
  }: GetNewDateTime = {}): Moment.Moment => {
    const newInterval = !_.isNumber(interval) ? 1 : interval
    const newIntervalUnit = !isValidDatetimeUnit(intervalUnit)
      ? DATE_UNIT_TYPES.days
      : intervalUnit

    const fullDateUnits = [
      DATE_UNIT_TYPES.days,
      DATE_UNIT_TYPES.weeks,
      DATE_UNIT_TYPES.months,
      DATE_UNIT_TYPES.years,
    ]

    const method = isAdded ? 'add' : 'subtract'

    const zonedMomentTime =
      timezone && isValidTimezone(timezone)
        ? moment(baseDatetime).tz(timezone)
        : moment(baseDatetime).utc()

    // https://sensorup.atlassian.net/browse/SPR-4440
    // N day/month/week/year ago = Start 12am of N day/week/month/year. The start date should be the full date when choosing N days/week/month/year
    // Example: 1 day ago until now = All of yesterday until now

    const fullDateMomentTime =
      isFullDate && _.includes(fullDateUnits, newIntervalUnit)
        ? zonedMomentTime[method](newInterval, newIntervalUnit).startOf(
            DATE_UNIT_TYPES.days
          )
        : moment.utc(baseDatetime)[method](newInterval, newIntervalUnit)

    const snapFn = isStart ? 'startOf' : 'endOf'

    return snappedUnit
      ? fullDateMomentTime[snapFn](snappedUnit)
      : fullDateMomentTime
  }

export const getPreviousUtcDate = (props: GetNewDateTime): Moment.Moment =>
  getNewDateTime(false)(props)

export const getFutureUtcDate = (props: GetNewDateTime): Moment.Moment =>
  getNewDateTime(true)(props)

export const getUtcDateTime = (datetime?: Datetime): Moment.Moment =>
  datetime
    ? moment.isMoment(datetime)
      ? datetime.utc()
      : moment.utc(datetime)
    : moment.utc()

export const getUtcDateTimeString = (datetime?: Datetime): UtcTimeString =>
  getUtcDateTime(datetime).toISOString()

export const getFormattedUtcNow = (format?: string): UtcTimeString => {
  const utcNow = moment.utc()
  return format ? utcNow.format(format) : utcNow.toISOString()
}

export const getTimezoneTimeStringFromUtcDateTime = (
  time: Datetime,
  timezone: Timezone,
  format = DATE_HOUR_MINUTE_SECOND_FORMAT_STANDARD
): UtcTimeString => {
  return isValidTimezone(timezone)
    ? moment(time).tz(timezone).format(format)
    : getUtcDateTimeString(time)
}

export const getMomentFromUtcDateTime = (
  time: Datetime,
  timezone: Timezone
): Moment.Moment => {
  return isValidTimezone(timezone)
    ? moment(time).tz(timezone)
    : getUtcDateTime(time)
}

export const getUtcDateTimeRangeToToday = (
  value = 1,
  unit = DATE_UNIT_TYPES.days
): {
  start: Moment.Moment
  end: Moment.Moment
} | null => {
  if (!_.isInteger(value) || !isValidDatetimeUnit(unit)) return null

  return {
    start: moment.utc().subtract(value, unit),
    end: moment.utc(),
  }
}

export const getFormattedUtcDateTimeRangeToToday = (
  value = 1,
  unit = DATE_UNIT_TYPES.days,
  format = DATE_HOUR_MINUTE_FORMAT
): DateTimeRangeObj | null => {
  if (!_.isInteger(value) || !isValidDatetimeUnit(unit)) return null

  const { start, end } = getUtcDateTimeRangeToToday(value, unit) || {}
  return {
    start: start?.format(format),
    end: end?.format(format),
  }
}

export const convertDateTimeRangeToString = ({
  start = null,
  end = null,
}: {
  start: UtcTimeString | null
  end: UtcTimeString | null
}): UtcTimeString | null => {
  if (!start || !end) return null

  return `${start}/${end}`
}

export const getElapsedSecondsToNow = (start?: Moment.Moment): number => {
  const now = moment()
  const elapsedSeconds = now.diff(start, 'seconds')
  return elapsedSeconds > 0 ? elapsedSeconds : 0
}

export const isTimeValidWithIsoFormat = (time?: UtcTimeString): boolean =>
  moment(time, moment.ISO_8601, true).isValid()

export const isDateTimeRangeValid = (
  startTime?: UtcTimeString,
  endTime?: UtcTimeString
): boolean => {
  if (!startTime || !endTime) return false
  if (
    !isTimeValidWithIsoFormat(startTime) ||
    !isTimeValidWithIsoFormat(endTime)
  )
    return false
  return moment(startTime).isBefore(moment(endTime))
}

export const getMaximumTimeRange = (
  timeRange: { startTime: UtcTimeString; endTime: UtcTimeString },
  timerRangeTarget: { startTime: UtcTimeString; endTime: UtcTimeString }
): { startTime: UtcTimeString; endTime: UtcTimeString } => {
  const startTime = isDateTimeRangeValid(
    timeRange.startTime,
    timerRangeTarget.startTime
  )
    ? timeRange.startTime
    : timerRangeTarget.startTime
  const endTime = moment
    .utc(timeRange.endTime)
    .isAfter(moment.utc(timerRangeTarget.endTime))
    ? timeRange.endTime
    : timerRangeTarget.endTime
  return { startTime, endTime }
}

export const getMinimumTimeRange = (
  timeRange: DateTimeRangeObj,
  timerRangeTarget: DateTimeRangeObj
): DateTimeRangeObj | null => {
  if (!timeRange) return timerRangeTarget
  if (!timerRangeTarget) return timeRange

  const start = isDateTimeRangeValid(timeRange.start, timerRangeTarget.start)
    ? timerRangeTarget.start
    : timeRange.start
  const end = moment
    .utc(timeRange.end)
    .isAfter(moment.utc(timerRangeTarget.end))
    ? timerRangeTarget.end
    : timeRange.end

  return moment(start).isAfter(end) ? null : { start, end }
}
/**
 * Get relative time from now in string format
 * @param {String|Moment} time
 * @param {Boolean} suffix: True means you can get the value without the suffix.
 *
 * @return {String} relative time string
 */
export const getFromNow = (time: Datetime, suffix = false): string =>
  moment.utc(time).fromNow(suffix)

const DATETIME_ISO_FORMAT_REGEX =
  /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$/

/**
 * Check whether the given string is a valid ISO date time
 * @param {String} datetime datetime string
 *
 * @return {Boolean}
 */
export const isValidISODatetimeString = (datetime?: UtcISOString): boolean =>
  DATETIME_ISO_FORMAT_REGEX.test(datetime)

/**
 * Get formatted relative datetime range
 * @param {Number} value period
 * @param {String} unit date unit
 *
 * @return {Object} {start,end}
 */
export const getFormattedRelativeTimeRange = ({
  value = 1,
  unit = DATE_UNIT_TYPES.days,
}: {
  value?: number
  unit?: IntervalUnit
}): DateTimeRangeObj => {
  if (!_.isInteger(value) || !isValidDatetimeUnit(unit)) return null

  const startOfUnit =
    unit === DATE_UNIT_TYPES.seconds ? unit : DATE_UNIT_TYPES.minutes
  return {
    start: getSnappedDatetimeStr({
      baseDatetime: moment.utc().subtract(value, unit),
      unit: startOfUnit,
    }),
    end: getSnappedDatetimeStr({ unit: startOfUnit }),
  }
}

export const getDateTimeDuration = (
  start: Datetime,
  end?: Datetime,
  useCurrentTimeIfNoEnd = false
): Moment.Duration => {
  const endTime = end
    ? moment.utc(end)
    : useCurrentTimeIfNoEnd
    ? moment.utc()
    : null

  if (!endTime) {
    return moment.duration(0)
  }

  return moment.duration(endTime.diff(moment.utc(start)))
}

export const getDateTimeDurationInMs = (
  start: Datetime,
  end?: Datetime,
  useCurrentTimeIfNoEnd = false
): number | null => {
  if (!start) {
    return null
  }

  return getDateTimeDuration(start, end, useCurrentTimeIfNoEnd).asMilliseconds()
}

export const timePluralFormatter = (
  value: number,
  singularUnit: string
): string => {
  return `${value} ${singularUnit}${value > 1 ? 's' : ''}`
}

const UNITS = ['year', 'month', 'week', 'day', 'hour', 'min', 'second']

export const formatDuration = (
  duration: Moment.Duration,
  shortForm = false,
  startUnit: string = 'year',
  selectedUnits: string[] = UNITS
): string => {
  const milliseconds = duration.asMilliseconds()
  if (milliseconds < SECOND_IN_MS) {
    return `${milliseconds} ms`
  }

  const startIndex = UNITS.indexOf(startUnit)
  const filteredUnits = UNITS.slice(startIndex).filter(unit =>
    selectedUnits.includes(unit)
  )

  const unitMapping = {
    year: {
      getValue: () =>
        startUnit === 'year' ? duration.asYears() : duration.years(),
      shortLabel: 'y',
    },
    month: {
      getValue: () =>
        startUnit === 'month' ? duration.asMonths() : duration.months(),
      shortLabel: 'mo',
    },
    week: {
      getValue: () =>
        startUnit === 'week' ? duration.asWeeks() : duration.weeks(),
      shortLabel: 'w',
    },
    day: {
      getValue: () =>
        startUnit === 'day' ? duration.asDays() : duration.days(),
      shortLabel: 'd',
    },
    hour: {
      getValue: () =>
        startUnit === 'hour' ? duration.asHours() : duration.hours(),
      shortLabel: 'h',
    },
    min: {
      getValue: () =>
        startUnit === 'min' ? duration.asMinutes() : duration.minutes(),
      shortLabel: 'm',
    },
    second: {
      getValue: () =>
        startUnit === 'second' ? duration.asSeconds() : duration.seconds(),
      shortLabel: 's',
    },
  }

  const timeComponents = _.map(filteredUnits, unit => {
    const { getValue, shortLabel } = unitMapping[unit] ?? {}
    const value = getValue?.() ?? 0
    return {
      unit,
      shortLabel,
      value: Math.trunc(value),
    }
  })

  const formattedParts = timeComponents
    .filter(component => component.value > 0)
    .map(({ unit, value, shortLabel }) => {
      return shortForm
        ? `${value}${shortLabel}`
        : `${timePluralFormatter(value, unit)}`
    })

  return formattedParts.join(' ').trim()
}

export const getFriendlyDateTimeDuration = (
  start: UtcISOString,
  end?: UtcISOString,
  shortForm = false,
  startUnit?: string,
  selectedUnits: string[] = UNITS
): string => {
  if (!start || !end) {
    return ''
  }
  let startTime = moment.utc(start)
  let endTime = moment.utc(end)
  if (endTime.isBefore(startTime)) {
    ;[startTime, endTime] = [endTime, startTime]
  }
  const duration = moment.duration(endTime.diff(startTime))
  return formatDuration(duration, shortForm, startUnit, selectedUnits)
}
export const getDiffBetweenDateTime = (
  date1: Datetime,
  date2: Datetime,
  timezone?: Timezone
): number => {
  const newDate1 = timezone ? moment(date1).tz(timezone) : moment(date1).utc()

  const newDate2 = timezone ? moment(date2).tz(timezone) : moment(date2).utc()

  return newDate2.diff(newDate1, 'days')
}

export const getDurationAsIntervalUnit = (
  start: Datetime,
  end: Datetime,
  intervalUnit: IntervalUnit,
  shouldCeil = true
): number | undefined => {
  if (!isValidDatetimeUnit(intervalUnit)) return undefined

  const durationFunctionName = `as${_.capitalize(intervalUnit)}`
  const duration = getDateTimeDuration(start, end)
  const result = duration[durationFunctionName]()
  return shouldCeil ? Math.ceil(result) : result
}

export const getValidDateTimeUnitOptionsAndUnit = (
  startTime: UtcISOString,
  endTime: UtcISOString
): {
  unit: IntervalUnit
  validOptions: Options
} => {
  moment.updateLocale('en', {
    relativeTime: MOMENT_LOCALE_RELATIVE_TIME,
  })
  const start = moment.utc(startTime)
  const end = moment.utc(endTime)
  if (start.isSameOrAfter(end)) return {}

  const timeFromX = end.from(start, true)
  const readableUnit = timeFromX.split(' ').pop()
  const findIndex = _.findIndex(INTERVAL_UNIT_OPTIONS, ['value', readableUnit])
  const validOptions = _.slice(INTERVAL_UNIT_OPTIONS, findIndex)
  return {
    unit: readableUnit,
    validOptions,
  }
}

export const getSuggestedDateTimeUnitOptions = (
  startTime: UtcISOString,
  endTime: UtcISOString
): Options => {
  const duration = getDateTimeDuration(startTime, endTime)
  const days = duration.asDays()
  let units = []
  if (days <= 1) {
    units = [
      DATE_UNIT_TYPES.hours,
      DATE_UNIT_TYPES.minutes,
      DATE_UNIT_TYPES.seconds,
    ]
  } else if (days > 1 && days <= 7) {
    units = [DATE_UNIT_TYPES.days, DATE_UNIT_TYPES.hours]
  } else {
    units = [
      DATE_UNIT_TYPES.days,
      DATE_UNIT_TYPES.months,
      DATE_UNIT_TYPES.years,
    ]
  }
  return INTERVAL_UNIT_OPTIONS.filter(option => units.includes(option.value))
}

export const getHourMinuteOptions = (
  timezone: Timezone,
  rangeUnit: IntervalUnit,
  targetUnit: IntervalUnit,
  targetUnitFormat: string,
  datetime?: Timezone
): Options<string> => {
  const start = getSnappedDateMoment({
    baseDatetime: datetime,
    unit: rangeUnit,
    timezone,
  })
  const end = getSnappedDateMoment({
    baseDatetime: datetime,
    unit: rangeUnit,
    timezone,
    isStart: false,
  })
  const range = moment.range(start, end)
  return Array.from(range.by(targetUnit)).map(m => {
    return {
      label: m.format(targetUnitFormat),
      value: m.utc()[targetUnit](),
    }
  })
}

export const getHoursOptionsUTCToTimezone = (
  timezone: Timezone,
  datetime?: UtcISOString
): Options =>
  getHourMinuteOptions(
    timezone,
    DATE_UNIT_TYPES.days,
    DATE_UNIT_TYPES.hours,
    'HH',
    datetime
  )

export const getMinutesOptions = (): Options =>
  getHourMinuteOptions(
    null,
    DATE_UNIT_TYPES.hours,
    DATE_UNIT_TYPES.minutes,
    'mm'
  )

/**
 * get datetime with timezone information from time string
 * @param {String} isoDate time in ISO 8601 format
 * @param {String} timezone
 *
 * @return {Date}
 */
export const getDatetimeTimezoneLocalTimeFromUtcISOString = (
  isoDate: UtcISOString,
  timezone: Timezone
): Date => {
  // Obtain a Date instance that will render the equivalent timezone time for the UTC date
  const date = isoDate ? new Date(isoDate) : new Date()

  return timezone ? toZonedTime(date, timezone) : toZonedTime(date, UTC)
}

export const getUtcISOStringFromTimezoneDate = (
  date: Date,
  timezone: Timezone
): UtcISOString => {
  if (!date) return date

  const utcDate = timezone
    ? fromZonedTime(date, timezone)
    : fromZonedTime(date, '+00:00')
  return utcDate.toISOString()
}

export const isTimeRangeEqual = (
  newTimeRange: DateTimeRangeObj,
  oldTimeRange: DateTimeRangeObj
): boolean => {
  const { start: newStart, end: newEnd } = newTimeRange || {}
  const { start: oldStart, end: oldEnd } = oldTimeRange || {}

  if (!newStart && !newEnd && !oldStart && !oldEnd) return true

  return (
    moment(newStart).isSame(moment(oldStart)) &&
    moment(newEnd).isSame(moment(oldEnd))
  )
}

export const getTimeRangeString = (
  datetimeRange?: Partial<DateTimeRangeObj>
): string => {
  const { start, end } = datetimeRange || {}
  return `${start}-${end}`
}

export const getTimeArrayBasedOnRangeAndInterval = (
  start: Moment.Moment,
  end: string,
  unit = DATE_UNIT_TYPES.hours,
  timezone?: Timezone
): string[] => {
  // Any of the units accepted by moment.js' add method may be used.
  // E.g.: 'years' | 'quarters' | 'months' | 'weeks' | 'days' | 'hours' | 'minutes' | 'seconds' | 'milliseconds'
  const range = timezone
    ? moment.range(moment.parseZone(start), moment.parseZone(end))
    : moment.range(moment.utc(start), moment.utc(end))

  return Array.from(range.by(unit)).map(m =>
    timezone
      ? m.format(DATE_HOUR_MINUTE_SECOND_TIMEZONE_FORMAT)
      : m.toISOString()
  )
}

export const getValidDatetimeUnit = (unit: IntervalUnit): IntervalUnit => {
  return isValidDatetimeUnit(unit) ? unit : DATE_UNIT_TYPES.seconds
}

export const isDatetimeInRange = (
  datetime: UtcISOString,
  datetimeRange: DateTimeRangeObj
): boolean => {
  const { start, end } = datetimeRange
  return (
    moment.utc(end).isSameOrAfter(datetime) &&
    moment.utc(start).isSameOrBefore(datetime)
  )
}

export const isExpired = (exp: number): boolean => {
  return exp < moment().unix()
}

export const parseRelativeTime = (
  relativeTime: string,
  reference?: Date
): UtcISOString => {
  const parsedDate = reference
    ? chrono.parseDate(relativeTime, reference)
    : chrono.parseDate(relativeTime)

  return moment.utc(parsedDate).toISOString()
}

export const setNewTimeString = ({
  oldDateTime,
  timezone,
  setObject,
}: {
  oldDateTime: UtcISOString
  timezone: Timezone
  setObject: { hour: number; minute: number }
}): UtcISOString => {
  if (_.isEmpty(setObject)) return getUtcDateTimeString(oldDateTime)

  const newMoment = timezone
    ? moment(oldDateTime).tz(timezone).set(setObject)
    : moment(oldDateTime).utc().set(setObject)

  return getUtcDateTimeString(newMoment)
}

export const setNewHourMinute = ({
  oldDateTime,
  timezone,
  newTime,
}: {
  oldDateTime?: string
  timezone?: Timezone
  newTime: string
}): UtcISOString => {
  if (!newTime || !_.isString(newTime)) return newTime

  const [hour, minute] = _.split(newTime, ':')
  if (Number(hour) < 0 || Number(minute) < 0) return newTime
  return setNewTimeString({
    oldDateTime:
      oldDateTime ||
      getSnappedDatetimeStr({
        baseDatetime: getNowISOString(),
        unit: DATE_UNIT_TYPES.minutes,
      }),
    timezone,
    setObject: { hour, minute },
  })
}

export const convertUnixTimestampToISOString = (
  unixTimestamp: number
): UtcISOString | undefined =>
  unixTimestamp ? moment(unixTimestamp * 1000).toISOString() : undefined

export const getUnitTimeDiffMs = (exp = 0, bufferSeconds = 0): number => {
  return exp * 1000 - bufferSeconds * 1000 - Moment.now()
}

export const isDateTimePreciseToDate = (
  datetime: UtcTimeString,
  timezone?: Timezone
): boolean => {
  return (
    getSnappedDatetimeStr({
      baseDatetime: datetime,
      unit: DATE_UNIT_TYPES.days,
      timezone,
    }) === datetime
  )
}

export const overrideTimeForGivenDate = (
  baseStart: UtcTimeString,
  baseEnd: UtcTimeString
): UtcTimeString => {
  const hour = moment.utc(baseStart).hour()
  const minute = moment.utc(baseStart).minute()
  const second = moment.utc(baseStart).second()
  const millisecond = moment.utc(baseStart).millisecond()

  return moment
    .utc(baseEnd)
    .set({ hour, minute, second, millisecond })
    .toISOString()
}

export const isSameYear = (
  date1?: string | Moment.Moment,
  date2?: string | Moment.Moment
): boolean => {
  return moment.utc(date1).year() === moment.utc(date2).year()
}

export const isSameMonth = (
  date1?: string | Moment.Moment,
  date2?: string | Moment.Moment
): boolean => {
  return moment.utc(date1).month() === moment.utc(date2).month()
}

export const isSameYearAndMonth = (
  date1?: string | Moment.Moment,
  date2?: string | Moment.Moment
): boolean => {
  return isSameYear(date1, date2) && isSameMonth(date1, date2)
}

/** Returns year, month, and day from the provided date (just numbers) */
export const getYearMonthDay = (
  date: string
): { month: string; year: string; day: string } => {
  const momentDate = moment(date)

  return {
    month: momentDate.format(MONTH_NUMBER_FORMAT),
    year: momentDate.format(YEAR_FORMAT),
    day: momentDate.format(DAY_NUMBER_FORMAT),
  }
}

export const getTodayISOString = (length = 10) =>
  new Date().toISOString().substring(0, length)
