import { useMemo, useState } from 'react'

import type { IconButtonProps } from 'components/common/IconButton'

const useCopyLinkButton = ({
  link,
  iconSize = 18,
  copyIcon = 'LinkIcon',
  copiedIcon = 'AiFillCheckCircle',
  resetDelay = 1000,
}: {
  link: string
  iconSize?: number
  copyIcon?: string
  copiedIcon?: string
  resetDelay?: number
}): IconButtonProps | undefined => {
  const [isCopied, toggleIsCopied] = useState(false)

  const copyButton = useMemo(
    () =>
      isCopied
        ? {
            icon: copiedIcon,
            size: iconSize,
            label: 'Copied',
            key: 'copied',
          }
        : {
            icon: copyIcon,
            onClick: () => {
              navigator.clipboard.writeText(link)

              toggleIsCopied(true)

              setTimeout(() => {
                toggleIsCopied(false)
              }, resetDelay)
            },
            size: iconSize,
            label: 'Copy link',
            key: 'copyLink',
          },
    [copiedIcon, copyIcon, iconSize, isCopied, link, resetDelay, toggleIsCopied]
  )

  return link ? copyButton : undefined
}

export default useCopyLinkButton
