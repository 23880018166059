// libraries
import React, { useState, useMemo, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

// components
import { MultiSelect } from 'components/common'

// utils
import { reportMessage } from 'helpers/log'
import { getDatasetOptionByDatasetNameAndCatalogId } from 'helpers/unipipe'

export const DatasetPicker = ({ value, options, onChange, ...rest }) => {
  return (
    <MultiSelect
      className='datasetSelect'
      placeholder='Select a data source'
      isMulti={false}
      isClearable={false}
      {...rest}
      value={value}
      options={options}
      onChange={onChange}
    />
  )
}

DatasetPicker.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
    PropTypes.shape({}),
  ]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})),
}

DatasetPicker.defaultProps = {
  value: undefined,
  options: [],
}

export const GroupDatasetPicker = ({
  value,
  timeliness,
  onChange,
  catalogId,
  datasetOption,
  datasetGroupOptions,
  groupedDatasetsOptionsByTimeliness,
  selectedDatasetGroup,
  setSelectedDatasetGroup,
}) => {
  const [datasetValue, setDatasetValue] = useState(value)

  useEffect(() => {
    setDatasetValue(value)
  }, [value])

  const onDatasetGroupChange = useCallback(
    option => {
      setDatasetValue()
      setSelectedDatasetGroup(option.value)
    },
    [setSelectedDatasetGroup]
  )

  const canAccessDataset = useMemo(() => {
    if (!datasetValue) return true

    const hasDatasetOption = !!getDatasetOptionByDatasetNameAndCatalogId(
      _.flatMap(groupedDatasetsOptionsByTimeliness, 'options'),
      datasetValue,
      catalogId
    )

    if (!hasDatasetOption) {
      reportMessage(
        `Dataset <${datasetValue}> from catalog <${catalogId}> is not accessible.`,
        'warning',
        {
          dataset: datasetValue,
          catalogId,
          groupedDatasetsOptionsByTimeliness,
        }
      )
    }

    return hasDatasetOption
  }, [datasetValue, groupedDatasetsOptionsByTimeliness, catalogId])

  return (
    <>
      {!canAccessDataset && (
        <span className='message'>
          Sorry, the previous selected dataset is not accessible
        </span>
      )}
      {datasetGroupOptions.length > 1 && (
        <MultiSelect
          className='mb-1'
          value={selectedDatasetGroup || datasetOption.group}
          options={datasetGroupOptions}
          onChange={onDatasetGroupChange}
          placeholder='Select a group'
          isMulti={false}
          isClearable={false}
        />
      )}
      {_.isEmpty(groupedDatasetsOptionsByTimeliness) ? (
        <span className='message'>
          Sorry, there are currently no dataset options available
        </span>
      ) : (
        <DatasetPicker
          value={datasetValue}
          options={groupedDatasetsOptionsByTimeliness}
          onChange={onChange}
          group={timeliness}
        />
      )}
    </>
  )
}

GroupDatasetPicker.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
    PropTypes.shape({}),
  ]),
  timeliness: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  catalogId: PropTypes.string,
  datasetOption: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    group: PropTypes.string,
  }).isRequired,
  datasetGroupOptions: PropTypes.arrayOf(PropTypes.shape({})),
  groupedDatasetsOptionsByTimeliness: PropTypes.arrayOf(PropTypes.shape({})),
  selectedDatasetGroup: PropTypes.string.isRequired,
  setSelectedDatasetGroup: PropTypes.func.isRequired,
}

GroupDatasetPicker.defaultProps = {
  value: undefined,
  timeliness: undefined,
  catalogId: undefined,
  datasetGroupOptions: [],
  groupedDatasetsOptionsByTimeliness: [],
}

export default GroupDatasetPicker
