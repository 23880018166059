import { WatchQueryFetchPolicy, FetchPolicy } from '@apollo/client'

export const APOLLO_FETCH_POLICY: Record<
  string,
  WatchQueryFetchPolicy | FetchPolicy
> = {
  CACHE_FIRST: 'cache-first',
  CACHE_AND_NETWORK: 'cache-and-network',
  NETWORK_ONLY: 'network-only',
  CACHE_ONLY: 'cache-only',
  NO_CACHE: 'no-cache',
  STANDBY: 'standby',
}
