import _ from 'lodash'
import { useCallback, useMemo } from 'react'

// utils
import { useCurrentUser, useRecoilLoadableResult } from 'hooks'
import { assetSitesOptionsState } from 'app/MissionControlMethaneSolution/recoilStore/assetTypeStore'
import {
  getAsyncPaginateNewOptions,
  loadAsyncPaginateSelectOptions,
} from 'components/common/MultiSelect/utils'

// components
import FilterAsyncDropdown from 'components/common/List/FilterBuilder/FilterAsyncDropdown'

import type { Options } from 'types/common'
import { type AsyncPaginateAdditional } from 'components/common/MultiSelect'

const AssetSitesFilterAsyncDropdown = ({
  value: selectedAssetOptions,
  ...rest
}: {
  value: Options
  title: string
}) => {
  const { sessionUserGroup } = useCurrentUser()

  const { data: assetSiteOptions, loading: isAssetSitesListLoading } =
    useRecoilLoadableResult(assetSitesOptionsState)

  const assetSiteOptionsBySessionGroup = useMemo(() => {
    return sessionUserGroup
      ? _.filter(assetSiteOptions, { group: sessionUserGroup })
      : assetSiteOptions
  }, [assetSiteOptions, sessionUserGroup])

  const loadAssetSiteOptions = useCallback(
    (
      search: string,
      _loadedOptions: Options,
      additional: AsyncPaginateAdditional
    ) => {
      const { page = 1 } = additional ?? {}

      const { options, hasMore } = loadAsyncPaginateSelectOptions({
        page,
        search,
        options: assetSiteOptionsBySessionGroup as Options,
      })

      const newOptions = getAsyncPaginateNewOptions({
        page,
        options,
        selectedOptions: selectedAssetOptions,
      })

      return {
        options: newOptions,
        hasMore,
        additional: {
          page: page + 1,
        },
      }
    },
    [assetSiteOptionsBySessionGroup, selectedAssetOptions]
  )
  const cacheUniqs = useMemo(
    () => [assetSiteOptionsBySessionGroup],
    [assetSiteOptionsBySessionGroup]
  )

  return (
    <FilterAsyncDropdown
      {..._.omit(rest, ['filters'])}
      isMulti
      loadOptions={loadAssetSiteOptions}
      isAsyncPaginate
      isAsync={false}
      value={selectedAssetOptions}
      isLoading={isAssetSitesListLoading}
      cacheUniqs={cacheUniqs}
    />
  )
}

export default AssetSitesFilterAsyncDropdown
