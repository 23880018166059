// libraries
import { useMemo, ReactElement, useCallback } from 'react'
import _ from 'lodash'

// utils
import { displayTime, displayValue } from 'helpers/utils'
import useImageCarousel from 'components/common/Image/useImageCarousel'
import { useTimezone } from 'hooks'
import { getImageUrlFromDataCollectionFormMedia } from 'components/common/JsonForm/SignatureWidget'
import { getNewUtcDateTime } from 'components/common/DateTime/TimePicker'
import type { UtcISOString } from 'types/datetime'
import type { DataCollectionFormMediaView, JSONFormBody } from 'types/issue'
import type { Point } from 'geojson'
import type { FormQuestionType, NumberWidgetUISchema } from 'types/formBuilder'

// components
import { IconButton } from 'components/common'
import DisplaySignature from 'components/common/JsonForm/SignatureWidget/Signature'
import MapWithMarker from 'components/common/JsonForm/GeoPointWidget/MapWithMarker'
import IssueVideoList from 'components/issue/common/IssueVideoList'
import Table from 'components/assets/assetsProfile/widgets/common/Table'
import IssueAttachmentList from 'components/issue/common/IssueAttachmentList'

// constants
import { THEMES } from 'constants/colour'
import { NO_AVAILABLE_DATA } from 'constants/common'
import {
  DATE_TIME_FIELD_TYPES,
  FIELD_ADDON_POSITIONS,
  FORM_QUESTION_TYPES,
} from 'constants/formBuilder'
import { TIME_FORMAT } from 'constants/datetime'

type Signature = {
  mediaKey: string
  timestamp: UtcISOString
}

type PropertyValue =
  | string
  | number
  | boolean
  | []
  | UtcISOString
  | Point
  | Signature

type Property = {
  label: string
  value: PropertyValue
  questionType: FormQuestionType
  questionProps?: JSONFormBody
}

type IssueFormResultDisplayProps = {
  result: Property
  dataCollectionFormMedia?: DataCollectionFormMediaView[]
  views: DataCollectionFormMediaView[]
  videos: DataCollectionFormMediaView[]
}

const IssueFormResultDisplay = ({
  result,
  dataCollectionFormMedia,
  views,
  videos,
}: IssueFormResultDisplayProps): ReactElement => {
  const { value, questionType, questionProps } = result

  const { timezone } = useTimezone()

  const { renderCarousel, renderImages } = useImageCarousel({
    imageResources: views,
  })

  const renderVideos = useCallback(
    fieldValue => (
      <IssueVideoList videoResources={videos} fieldValue={fieldValue} />
    ),
    [videos]
  )

  const renderFiles = useCallback(
    fieldValue => (
      <IssueAttachmentList
        fieldValue={fieldValue}
        dataCollectionFormMedia={dataCollectionFormMedia}
      />
    ),
    [dataCollectionFormMedia]
  )

  const renderCheckboxList = useCallback(
    (selected: string[]): ReactElement => {
      const { schema: { items: { enum: list } = {} } = {} } = questionProps

      return (
        <>
          {_.map(list, item => {
            const isSelected = _.includes(selected, item)
            return (
              <div key={item}>
                <IconButton
                  icon={isSelected ? 'MdCheckBox' : 'MdCheckBoxOutlineBlank'}
                  size={16}
                />
                {item}
              </div>
            )
          })}
        </>
      )
    },
    [questionProps]
  )

  const renderGeolocation = useCallback((): ReactElement | string => {
    const { coordinates } = (value || {}) as Point
    if (_.isEmpty(coordinates)) return NO_AVAILABLE_DATA

    return <MapWithMarker point={value as Point} readonly />
  }, [value])

  const renderSignature = useCallback((): ReactElement | string => {
    const { timestamp, mediaKey } = (value || {}) as Signature

    const signatureImageUrl = getImageUrlFromDataCollectionFormMedia(
      views,
      mediaKey
    )

    if (!signatureImageUrl) return NO_AVAILABLE_DATA

    return (
      <DisplaySignature imageUrl={signatureImageUrl} timestamp={timestamp} />
    )
  }, [value, views])

  const renderList = useCallback((): ReactElement => {
    const { schema, uischema } = questionProps
    const propertiesSchema = schema.items?.properties
    const propertiesOrder = _.get(uischema, 'items.ui:order', [])
    const columnWidth = 100 / propertiesOrder.length

    const columns = propertiesOrder.map(propertyKey => ({
      accessor: propertyKey,
      Header: propertiesSchema[propertyKey]?.title,
      width: columnWidth,
    }))

    return (
      <Table columns={columns} data={value} theme={THEMES.light} withHeader />
    )
  }, [questionProps, value])

  const renderNumberWithUnits = useCallback(() => {
    const { label, position, visible } = _.get(
      questionProps,
      'uischema.ui:options.addonLabel',
      {} as NumberWidgetUISchema['ui:options']['addonLabel']
    )
    const displayedValue = displayValue(value)

    if (!visible || !label) return displayedValue

    const isOnLeft = position === FIELD_ADDON_POSITIONS.left

    return (
      <span>
        {isOnLeft ? `${label} ${displayedValue}` : `${displayedValue} ${label}`}
      </span>
    )
  }, [questionProps, value])

  const display = useMemo(() => {
    if (_.isNil(value)) return NO_AVAILABLE_DATA

    if (questionType === FORM_QUESTION_TYPES.DATETIME) {
      const {
        schema: { format },
      } = questionProps

      switch (format) {
        case DATE_TIME_FIELD_TYPES.date:
          // Date
          return value

        case DATE_TIME_FIELD_TYPES['date-time']:
          // Date time
          return displayTime({
            datetime: value,
            timezone,
          })

        case DATE_TIME_FIELD_TYPES.time: {
          // Time
          const newDatetime = getNewUtcDateTime({
            utcTime: value,
            defaultToCurrent: true,
          })

          return displayTime({
            datetime: newDatetime,
            timezone,
            timeFormat: TIME_FORMAT,
          })
        }

        default:
          return displayValue(value)
      }
    }

    const renderMap = {
      [FORM_QUESTION_TYPES.IMAGE_UPLOADER]: renderImages,
      [FORM_QUESTION_TYPES.VIDEO_UPLOADER]: renderVideos,
      [FORM_QUESTION_TYPES.PDF_UPLOADER]: renderFiles,
      [FORM_QUESTION_TYPES.SIGNATURE]: renderSignature,
      [FORM_QUESTION_TYPES.CHECKBOX_LIST]: renderCheckboxList,
      [FORM_QUESTION_TYPES.GEO_POINT]: renderGeolocation,
      [FORM_QUESTION_TYPES.LIST]: renderList,
      [FORM_QUESTION_TYPES.NUMBER]: renderNumberWithUnits,
    }

    const renderFunction = renderMap[questionType] || displayValue

    return renderFunction(value)
  }, [
    questionProps,
    questionType,
    renderCheckboxList,
    renderGeolocation,
    renderImages,
    renderVideos,
    renderFiles,
    renderSignature,
    renderList,
    renderNumberWithUnits,
    timezone,
    value,
  ])

  return (
    <>
      {display}
      {renderCarousel()}
    </>
  )
}

export default IssueFormResultDisplay
