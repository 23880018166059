import {
  ReactElement,
  useEffect,
  useCallback,
  useMemo,
  useState,
  Dispatch,
} from 'react'
import _ from 'lodash'

// constants
import { THEMES } from 'constants/colour'
import { PROPERTY_TABLE_COLUMNS } from 'constants/assets'
import { ISSUE_TYPES } from 'constants/issue'

// utils
import {
  useDataCollectionStateData,
  useCurrentActiveIssue,
} from 'components/issue/hooks'
import {
  getViewsFromIssueCollectionData,
  getIssueStatusLabel,
  getVideosFromIssueCollectionData,
} from 'helpers/issue'

// components
import { Loading, IconButton } from 'components/common'
import IssueFormResultDisplay from 'components/issue/common/IssueFormResultDisplay'
import IssueList from 'components/issue/IssueList'
import Table, {
  DataItem,
} from 'components/assets/assetsProfile/widgets/common/Table'
import { StyledSubtitle } from 'components/assets/assetsProfile/widgets/common/style'

import type {
  SubTaskDataCollectionStateData,
  IssueStateData,
  IssueStateDataCollectionData,
  InternalIssueStateDataCollectionData,
  IssueGeojson,
  DataCollectionStateData,
} from 'types/issue'
import type { ThemeType } from 'types/common'
import { IssueTaskDataCollectionForms } from 'recoilStore/issuesStore'

export type Header = string | ReactElement | undefined

type IssueTableProps = {
  issueId: string
  theme?: ThemeType
  setHeader: Dispatch<Header>
  hideInvalidValues?: boolean
}

const getSubTaskSummaryData = (
  prettifiedDataCollectionStateData: SubTaskDataCollectionStateData[]
) => {
  return _.map(prettifiedDataCollectionStateData, ({ status, ...rest }) => {
    const statusLabel = getIssueStatusLabel(status)
    return { ...rest, status: statusLabel }
  })
}

const getSubTaskSummaryColumn = () => {
  return [
    {
      Header: 'Subtasks',
      accessor: 'title',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
  ]
}

export const getStateDataCollectionData = (
  data: IssueStateData
): DataItem[] => {
  const {
    dataCollectionFormMedia,
    prettifiedDataCollectionResponses,
    taskType,
  } = (data || {}) as InternalIssueStateDataCollectionData

  const views = getViewsFromIssueCollectionData({
    items: prettifiedDataCollectionResponses,
    imageResources: dataCollectionFormMedia,
    taskType,
  })
  const videos = getVideosFromIssueCollectionData({
    prettifiedDataCollectionResponses,
    dataCollectionFormMedia,
  })

  return _.map(prettifiedDataCollectionResponses, response => {
    const { label, value } = response || {}
    return {
      propertyKey: label,
      propertyValue: value ? (
        <IssueFormResultDisplay
          result={response}
          views={views}
          videos={videos}
          dataCollectionFormMedia={dataCollectionFormMedia}
        />
      ) : undefined,
    }
  })
}

export const isSubTaskStateData = (statesData: IssueStateData[]): boolean => {
  const firstData = _.first(
    _.flatMap(statesData)
  ) as SubTaskDataCollectionStateData
  return firstData?.type === ISSUE_TYPES.SUBTASK
}

const getTableData = (
  isSubTask: boolean,
  prettifiedDataCollectionStateData: DataCollectionStateData[],
  selectedSubTask?: SubTaskDataCollectionStateData
) => {
  if (isSubTask) {
    return selectedSubTask
      ? getStateDataCollectionData(selectedSubTask)
      : getSubTaskSummaryData(
          prettifiedDataCollectionStateData as SubTaskDataCollectionStateData[]
        )
  }
  return getStateDataCollectionData(
    _.first(prettifiedDataCollectionStateData) as IssueStateDataCollectionData
  )
}

const IssueTable = ({
  issueId,
  theme = THEMES.dark,
  setHeader,
  hideInvalidValues,
}: IssueTableProps): ReactElement => {
  const { prettifiedDataCollectionStateData, loading, error } =
    useDataCollectionStateData({ issueId, hideInvalidValues })

  const [selectedSubTask, setSelectSubTask] =
    useState<SubTaskDataCollectionStateData>()

  useEffect(() => {
    const newHeader = selectedSubTask && (
      <>
        <IconButton
          icon='FiArrowLeft'
          label='Back to issue list'
          onClick={() => setSelectSubTask(undefined)}
          size={16}
        />
        {selectedSubTask.title}
      </>
    )

    setHeader(newHeader)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubTask])

  const isSubTask: boolean = useMemo(() => {
    if (loading || error) return false

    return isSubTaskStateData(prettifiedDataCollectionStateData)
  }, [loading, error, prettifiedDataCollectionStateData])

  const data = useMemo(
    () =>
      getTableData(
        isSubTask,
        prettifiedDataCollectionStateData,
        selectedSubTask
      ),
    [prettifiedDataCollectionStateData, isSubTask, selectedSubTask]
  )

  const onSelectedSubTask = useCallback(
    issue => {
      if (!selectedSubTask) {
        setSelectSubTask(issue.original)
      }
    },
    [selectedSubTask]
  )

  const columns = useMemo(() => {
    return isSubTask && !selectedSubTask
      ? getSubTaskSummaryColumn()
      : PROPERTY_TABLE_COLUMNS
  }, [isSubTask, selectedSubTask])

  const renderTable = () => {
    if (error) return <>Error</>

    return _.isEmpty(data) ? (
      <StyledSubtitle theme={theme}>No information collected</StyledSubtitle>
    ) : (
      <Table
        data={data}
        columns={columns}
        theme={theme}
        {...(isSubTask &&
          !selectedSubTask && { onSelectedRow: onSelectedSubTask })}
      />
    )
  }

  return loading ? <Loading /> : renderTable()
}

type AssetIssuesListProps = {
  issues: IssueGeojson[]
  theme?: ThemeType
  setHeader: Dispatch<Header>
  hideInvalidValues: boolean
}

const AssetIssuesList = ({
  issues,
  setHeader,
  ...rest
}: AssetIssuesListProps): ReactElement => {
  const [currentActiveIssue, setCurrentActiveIssue] =
    useCurrentActiveIssue(issues)

  const setIssueHeader = useCallback(
    header => {
      const newHeader =
        header ||
        (currentActiveIssue && (
          <>
            <IconButton
              icon='FiArrowLeft'
              label='Back to issue list'
              onClick={() => setCurrentActiveIssue(undefined)}
              size={16}
            />
            {currentActiveIssue.properties.title}
          </>
        ))
      setHeader(newHeader)
    },
    [currentActiveIssue, setCurrentActiveIssue, setHeader]
  )

  useEffect(() => {
    setIssueHeader(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActiveIssue])

  return (
    <>
      {currentActiveIssue?.id ? (
        <IssueTable
          {...rest}
          issueId={currentActiveIssue.id}
          setHeader={setIssueHeader}
        />
      ) : (
        <IssueList
          {...rest}
          issues={issues}
          withBulkUpdate={false}
          className='w-100'
          currentActiveIssue={currentActiveIssue}
          setCurrentActiveIssue={setCurrentActiveIssue}
        />
      )}
    </>
  )
}

type AssetIssuesListWidgetWebProps = {
  issues: IssueGeojson[]
  theme: ThemeType
  setHeader: Dispatch<Header>
  hideInvalidValues: boolean
  issueTaskDataCollectionFormDefinition: IssueTaskDataCollectionForms
}

const AssetIssuesListWidgetWeb = ({
  issues,
  ...rest
}: AssetIssuesListWidgetWebProps): ReactElement => {
  return <AssetIssuesList {...rest} issues={issues} />
}

export default AssetIssuesListWidgetWeb
